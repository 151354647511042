/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.foil-image {
  position:relative;
  z-index:1;
  overflow:hidden;
  text-align:center;
  transform-origin:center center;
  transform-style:preserve-3d;
}

.foil-image::after {
  content:' ';
  position:absolute;
  height:200%;
  width:200%;
  top:0px;
  left:0px;
  z-index:2;
  -webkit-animation: moveafter 3s infinite linear;
  -moz-animation: moveafter 3s infinite linear;
  animation: moveafter 3s infinite linear;
  
background: -moz-linear-gradient(-45deg,  rgba(255,255,255,0) 0%, rgba(255,255,255,0) 45%, rgba(255, 255, 255, 0.5) 50%, rgba(255,255,255,0) 55%, rgba(255,255,255,0) 100%); /* FF3.6+ */
background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(45%,rgba(255,255,255,0)), color-stop(50%,rgba(255,255,255,0.2)), color-stop(55%,rgba(255,255,255,0)), color-stop(100%,rgba(255,255,255,0))); /* Chrome,Safari4+ */background: -webkit-linear-gradient(-45deg,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 45%,rgba(255,255,255,0.2) 50%,rgba(255,255,255,0) 55%,rgba(255,255,255,0) 100%); /* Chrome10+,Safari5.1+ */
background: -o-linear-gradient(-45deg,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 45%,rgba(255, 255, 255, 0.2) 50%,rgba(255,255,255,0) 55%,rgba(255,255,255,0) 100%); /* Opera 11.10+ */
background: -ms-linear-gradient(-45deg,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 45%,rgba(255,255,255,0.2) 50%,rgba(255,255,255,0) 55%,rgba(255,255,255,0) 100%); /* IE10+ */
background: linear-gradient(135deg,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 45%,rgba(255,255,255,0.2) 50%,rgba(255,255,255,0) 55%,rgba(255,255,255,0) 100%); /* W3C */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

}
/* .img {
  width:468px;
  height:600px;
  background:url('https://i.imgur.com/e4Br7db.jpg') center center no-repeat, linear-gradient(to bottom, rgba(30,87,153,0.5) 0%,rgba(20,96,19,0.5) 50%,rgba(30,87,153,0.5) 100%);
  background-size:100% auto;
}
.foil-image .img {
  background-blend-mode:multiply,normal;
} */

@-webkit-keyframes moveafter {
  0% {
    top:100%;
    left:100%;
  }
  50% {
    top:-100%;
    left:-100%;
  }
  100% {
    top:-200%;
    left:-200%;
  }
}
@-moz-keyframes moveafter {
  0% {
    top:100%;
    left:100%;
  }
  50% {
    top:-100%;
    left:-100%;
  }
  100% {
    top:-100%;
    left:-100%;
  }
}
@keyframes moveafter {
  0% {
    top:100%;
    left:100%;
  }
  50% {
    top:-100%;
    left:-100%;
  }
  100% {
    top:-100%;
    left:-100%;
  }
}