/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * capitolium-2:
 *   - http://typekit.com/eulas/00000000000000003b9af94d
 *   - http://typekit.com/eulas/00000000000000003b9af94c
 *   - http://typekit.com/eulas/00000000000000003b9af94e
 *   - http://typekit.com/eulas/00000000000000003b9af94f
 *   - http://typekit.com/eulas/00000000000000003b9af950
 * capitolium-news-2:
 *   - http://typekit.com/eulas/00000000000000007735a30b
 *   - http://typekit.com/eulas/00000000000000007735a30c
 *   - http://typekit.com/eulas/00000000000000007735a30d
 *   - http://typekit.com/eulas/00000000000000007735a30f
 *
 * © 2009-2021 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2021-01-22 23:43:36 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=duv7vwy&ht=tk&f=21689.21690.21691.21692.21693.25464.25466.25467.25468&a=26543291&app=typekit&e=css");

@font-face {
font-family:"capitolium-2";
src:url("https://use.typekit.net/af/e0a6cb/00000000000000003b9af94d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/e0a6cb/00000000000000003b9af94d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/e0a6cb/00000000000000003b9af94d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;
}

@font-face {
font-family:"capitolium-2";
src:url("https://use.typekit.net/af/6b46e2/00000000000000003b9af94c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/6b46e2/00000000000000003b9af94c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/6b46e2/00000000000000003b9af94c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:300;
}

@font-face {
font-family:"capitolium-2";
src:url("https://use.typekit.net/af/784461/00000000000000003b9af94e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/784461/00000000000000003b9af94e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/784461/00000000000000003b9af94e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;
}

@font-face {
font-family:"capitolium-2";
src:url("https://use.typekit.net/af/fb3132/00000000000000003b9af94f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/fb3132/00000000000000003b9af94f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/fb3132/00000000000000003b9af94f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:700;
}

@font-face {
font-family:"capitolium-2";
src:url("https://use.typekit.net/af/581d72/00000000000000003b9af950/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/581d72/00000000000000003b9af950/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/581d72/00000000000000003b9af950/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;
}

@font-face {
font-family:"capitolium-news-2";
src:url("https://use.typekit.net/af/e19d66/00000000000000007735a30b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/e19d66/00000000000000007735a30b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/e19d66/00000000000000007735a30b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;
}

@font-face {
font-family:"capitolium-news-2";
src:url("https://use.typekit.net/af/f78681/00000000000000007735a30c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/f78681/00000000000000007735a30c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/f78681/00000000000000007735a30c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:700;
}

@font-face {
font-family:"capitolium-news-2";
src:url("https://use.typekit.net/af/81cb67/00000000000000007735a30d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/81cb67/00000000000000007735a30d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/81cb67/00000000000000007735a30d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;
}

@font-face {
font-family:"capitolium-news-2";
src:url("https://use.typekit.net/af/972618/00000000000000007735a30f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/972618/00000000000000007735a30f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/972618/00000000000000007735a30f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;
}

.tk-capitolium-2 { font-family: "capitolium-2",serif; }
.tk-capitolium-news-2 { font-family: "capitolium-news-2",serif; }
